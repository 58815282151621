<template>
  <div>
    <GetStarted />
  </div>
</template>

<script>
import GetStarted from "@/components/GetStarted";
export default {
  components: {
    GetStarted,
  },
};
</script>

<style></style>
