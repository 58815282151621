<template>
  <div class="c-form-control">
    <label class="c-form-control__label"
      ><i :class="icon"></i>{{ label }}</label
    >
    <div class="u-position-relative">
      <input
        class="c-form-control__input c-form-control__input--icon"
        :class="{
          'c-form-control__input--block': block,
          'c-form-control__required': mandotary,
        }"
        type="tel"
        :title="title"
        :id="inputId"
        :disabled="disabled"
        :pattern="pattern"
        :value="phoneNumber"
        @loadeddata="
          (e) => {
            e.target.value = SET_TARGET_VALUE(e.target.value, e, 'loaded');
            phoneNumber = e.target.value;
          }
        "
        @focus="
          (e) => {
            e.target.value = SET_TARGET_VALUE(e.target.value, e, 'focus');
            phoneNumber = e.target.value;
          }
        "
        @input="
          (e) => {
            e.target.value = SET_TARGET_VALUE(e.target.value, e, 'input');
            phoneNumber = e.target.value;
          }
        "
        :required="mandotary ? 'true' : 'false'"
        name="a_telno"
        :placeholder="placeholder"
      />
      <i
        class="c-form-control__icon o-form-control__icon"
        :class="`icofont-phone`"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      first: true,
      phoneNumber: "",
      patternData: ["(N", "N", "N) ", "N", "N", "N ", "N", "N ", "N", "N"], // (NNN) NNN NN NN;
    };
  },
  props: {
    inputId: {
      default: "name",
    },
    disabled: {
      default: false,
    },
    title: {
      default: "",
    },
    value: {
      default: "",
    },
    label: {
      default: "",
    },
    icon: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    pattern: {
      default: null,
    },
    mandotary: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    value() {
      if (this.first) {
        let d = this.value;
        if (this.value.includes("+90")) d = this.value.substring(3);
        this.phoneNumber = this.SET_TARGET_VALUE(d, { data: null });
        this.first = false;
      }
    },
  },
  methods: {
    SET_TARGET_VALUE(val, e) {
      let exactVal = val;
      const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (e.data && !numbers.includes(e.data) && e.data !== null)
        return val.replace(/[^0-9||(||)|| ]/g, "");
      exactVal = val.replace(/\(/g, "");
      exactVal = exactVal.replace(/\)/g, "");
      exactVal = exactVal.replace(/ /g, "");
      if (
        e.data === null &&
        (val.length === 12 || val.length === 9 || val.length === 5)
      ) {
        exactVal = exactVal.slice(0, exactVal.length - 1);
      }
      if (exactVal.charAt(0) === "0") exactVal = exactVal.substring(1);
      this.$emit("input", "+90" + exactVal.slice(0, 10));
      if (e.data !== null)
        return this.patternData
          .slice(0, exactVal.length)
          .map((p, i) => p.replace("N", exactVal.charAt(i)))
          .join("");
      return this.patternData
        .slice(0, exactVal.length)
        .map((p, i) => p.replace("N", exactVal.charAt(i)))
        .join("");
    },
  },
  mounted() {
    let d = this.value;
    if (this.value.includes("+90")) d = this.value.substring(3);
    this.phoneNumber = this.SET_TARGET_VALUE(d, { data: null });
  },
};
</script>

<style></style>
