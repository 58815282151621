var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-form-control"},[_c('label',{staticClass:"c-form-control__label"},[_c('i',{class:_vm.icon}),_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"u-position-relative"},[_c('input',{staticClass:"c-form-control__input c-form-control__input--icon",class:{
        'c-form-control__input--block': _vm.block,
        'c-form-control__required': _vm.mandotary,
      },attrs:{"type":"tel","title":_vm.title,"id":_vm.inputId,"disabled":_vm.disabled,"pattern":_vm.pattern,"required":_vm.mandotary ? 'true' : 'false',"name":"a_telno","placeholder":_vm.placeholder},domProps:{"value":_vm.phoneNumber},on:{"loadeddata":(e) => {
          e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'loaded');
          _vm.phoneNumber = e.target.value;
        },"focus":(e) => {
          e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'focus');
          _vm.phoneNumber = e.target.value;
        },"input":(e) => {
          e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'input');
          _vm.phoneNumber = e.target.value;
        }}}),_c('i',{staticClass:"c-form-control__icon o-form-control__icon",class:`icofont-phone`})])])
}
var staticRenderFns = []

export { render, staticRenderFns }