<template>
  <div class="s-get-started__sign-up">
    <div class="s-get-started__sign-up__form-side">
      <Logo />
      <h1>{{ $t("createFreeAcademy") }}</h1>
      <p class="u-text-align-center">
        {{ $t("createFreeAcademyDescription") }}
      </p>
      <form @submit="CHECK_FORM">
        <BaseInput
          block
          type="email"
          icon="email"
          inputId="email"
          @input="(e) => $store.commit('UPDATE_FORM', { email: e })"
          :value="getForm.email"
          :label="$t('form.email')"
          :placeholder="$t('form.enterEmail')"
        />
        <PhoneInput
          block
          type="text"
          inputId="phone"
          @input="(e) => $store.commit('UPDATE_FORM', { phoneNo: e })"
          :value="getForm.phoneNo"
          :label="$t('form.phone')"
          :placeholder="$t('form.enterPhone')"
        />
        <BaseInput
          class="u-margin-top"
          block
          @input="
            (e) => $store.commit('UPDATE_FORM', { domain: GenerateSlug(e) })
          "
          :value="getForm.domain"
          mandatory
          :minlength="3"
          :maxlength="75"
          pattern="[A-Za-z]+"
          :label="$t('form.domain')"
          :placeholder="$t('form.domain')"
        >
          <template #append>
            <div class="c-form-control__append">.heocademy.com</div>
          </template>
        </BaseInput>
        <div>
          <small class="u-color-muted"> {{ $t("onlyLetter") }} </small>
        </div>
        <div>
          <small class="u-color-muted">
            {{ $t("onlyNtoNCharecter", { sn: 3, ln: 75 }) }}
          </small>
        </div>
        <Button
          type="submit"
          variant="primary"
          block
          class="u-margin-top-large"
        >
          {{ $t("form.startYourTrialDay") }}
          <i class="icofont-arrow-right"></i>
        </Button>
        <p class="u-margin-top" v-html="$t('policyMessage')"></p>
        <hr />
        <p v-html="$t('signUpMessage')"></p>
      </form>
    </div>
    <div class="s-get-started__sign-up__right-side">
      <img
        class="u-page-open"
        v-show="active === 1"
        :src="`/images/side1.webp`"
        alt=""
      />
      <img
        class="u-page-open"
        v-show="active === 2"
        :src="`/images/side2.webp`"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import BaseInput from "@base/Inputs/BaseInput.vue";
import PhoneInput from "@base/Inputs/PhoneInput.vue";
import Button from "@base/Button.vue";
import GenerateSlug from "@utils/slug";
export default {
  components: {
    PhoneInput,
    Logo,
    BaseInput,
    Button,
  },
  computed: {
    getForm() {
      return this.$store.state.form.form;
    },
  },
  data() {
    return {
      active: 1,
      activeMap: [1, 2],
      interval: -1,
    };
  },
  beforeDestroy() {
    console.log("çalıştı");
    clearInterval(this.interval);
  },
  methods: {
    GenerateSlug,
    CHECK_FORM(e) {
      e.preventDefault();
      this.$router.push({ name: "GetAccountInformation" });
    },
    INTERVAL() {
      this.interval = setInterval(() => {
        const index = Math.ceil(Math.random() * 2);
        this.active = this.activeMap[index] || 1;
      }, 5000);
    },
  },
  mounted() {
    const email = document.getElementById("email");
    if (email) email.focus();
    this.INTERVAL();
  },
};
</script>

<style></style>
